import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';

import formatSelector from '~/shared/util/data-selector';

const DATA_SELECTOR_ID = 'pricing_card';

const priceCardStyles = ({ theme }) => css`
  border-radius: ${theme.borderRadius.byte};
  flex-direction: column;
  padding: ${theme.spacings.bit} ${theme.spacings.giga};

  ${theme.mq.kilo} {
    flex-direction: row;
    height: 100%;
    padding: 0;
  }

  &:first-of-type {
    margin-bottom: ${theme.spacings.giga};

    ${theme.mq.kilo} {
      margin-bottom: 0;
    }
  }
`;

const PriceCard = styled(Card)(priceCardStyles);

const contentStyles = ({ theme }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${theme.fontWeight.regular};
  width: 100%;
  padding: ${theme.spacings.mega} 0;

  ${theme.mq.kilo} {
    display: inline-block;
    padding: 30px;
  }
`;

const contentExtraCardStyles = ({ hasExtraCard, theme }) =>
  hasExtraCard &&
  css`
    ${theme.mq.kilo} {
      width: 100%;

      &:first-of-type {
        display: none;
      }
    }
  `;

const contentTwoCardStyles = ({ cards, theme }) =>
  cards >= 2 &&
  css`
    position: relative;
    width: 100%;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      border: 0.5px solid var(--cui-border-normal);
    }

    &:first-of-type::before {
      display: none;
    }

    ${theme.mq.kilo} {
      width: 50%;

      &:nth-of-type(2) {
        padding-right: ${theme.spacings.mega};
      }

      &:last-of-type {
        padding-left: ${theme.spacings.mega};
      }

      &::before {
        position: absolute;
        content: '';
        top: ${theme.spacings.giga};
        bottom: ${theme.spacings.giga};
        left: 0;
        background-color: var(--cui-border-normal);
        display: block;
        width: 1px;
      }

      &:nth-of-type(2)::before {
        display: none;
      }
    }
  `;

const Content = styled('div')(
  contentStyles,
  contentTwoCardStyles,
  contentExtraCardStyles,
);

const attributeStyles = ({ theme }) => css`
  font-size: ${theme.typography.headline.one.fontSize};
  line-height: ${theme.typography.headline.one.lineHeight};
  font-weight: ${theme.fontWeight.bold};
  margin: 0 ${theme.spacings.peta} 0 0;
  white-space: nowrap;

  ${theme.mq.kilo} {
    margin: 0;
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.typography.headline.one.fontSize};
  }
`;

const Attribute = styled('span')(attributeStyles);

const descriptionStyles = ({ theme }) => css`
  font-size: ${theme.typography.body.two.fontSize};
  line-height: ${theme.typography.headline.four.lineHeight};
  color: var(--cui-fg-accent);
  font-weight: ${theme.fontWeight.bold};
  text-transform: uppercase;
  text-align: right;
  word-break: break-word;

  ${theme.mq.kilo} {
    margin-top: ${theme.spacings.byte};
    text-align: center;
  }

  ${theme.mq.mega} {
    font-size: ${theme.typography.subHeadline.fontSize};
  }
`;

const Description = styled('p')(descriptionStyles);

/**
 * PricingCard component, displayes a card showing device fee and price.
 */

const PricingCard = ({ feeCards = [], hasExtraCard = false }) => {
  if (isEmpty(feeCards)) {
    return null;
  }
  return (
    <PriceCard
      data-selector={formatSelector('card', DATA_SELECTOR_ID)}
      data-elbcontext="component:pricing_card"
    >
      {feeCards.map(({ amount, label }, i) => (
        <Content
          key={i}
          cards={feeCards.length}
          hasExtraCard={hasExtraCard}
          data-selector={formatSelector(`card_item_${i + 1}`, DATA_SELECTOR_ID)}
        >
          <Attribute
            data-selector={formatSelector(
              `card_item_${i + 1}_amount`,
              DATA_SELECTOR_ID,
            )}
          >
            {amount}
          </Attribute>
          <Description
            data-selector={formatSelector(
              `card_item_${i + 1}_label`,
              DATA_SELECTOR_ID,
            )}
          >
            {label}
          </Description>
        </Content>
      ))}
    </PriceCard>
  );
};

PricingCard.propTypes = {
  feeCards: PropTypes.array.isRequired,
  hasExtraCard: PropTypes.bool,
};

/**
 * @component
 */
export default PricingCard;
