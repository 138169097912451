import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';
import { Body, Headline } from '@sumup/circuit-ui';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import PricingCard from './components/PricingCard';

import { feesPropType } from '~/shared/util/shared-prop-types';
import FeesContext from '~/shared/providers/FeesContext';
import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';
import formatSelector from '~/shared/util/data-selector';
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

const DATA_SELECTOR_ID = 'pricing_cards';

const gridStyles = css`
  z-index: 25;
`;

const StyledCol = styled(Col)(gridStyles);

const StyledHeadline = styled(Headline)(
  ({ theme }) => css`
    text-align: center;
    margin-bottom: ${theme.spacings.giga};
  `,
);

const Description = styled.div`
  text-align: center;
  margin-bottom: ${(p) => p.theme.spacings.giga};
`;

const extraColStyles = ({ theme }) => css`
  display: none;
  ${theme.mq.kilo} {
    display: block;
  }
`;

const ExtraCol = styled(Col)(extraColStyles);

const wrapperStyles = () => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
`;

const Wrapper = styled('div')(wrapperStyles);

const finePrintStyles = ({ theme }) => css`
  font-size: ${theme.typography.subHeadline.fontSize};
  line-height: ${theme.typography.headline.four.lineHeight};

  ${theme.mq.mega} {
    font-size: ${theme.typography.subHeadline.fontSize};
  }

  margin-bottom: 0;
  margin-top: ${theme.spacings.tera};
  text-align: center;
`;

const FinePrint = styled(Body)(finePrintStyles);

/**
 * PricingCards component to display multiple cards
 * with pricing and fees of our devices.
 */

const PricingCards = ({
  headline,
  description,
  monthlyFeesLabel,
  transactionFeesLabel,
  creditCardFeesLabel,
  debitCardFeesLabel,
  finePrint,
  partnerFees,
  feeOverride: originalFeeOverride,
  className,
}) => {
  const fees = useContext(FeesContext);

  const { experiments } = useOptimizelyData();

  const feeOverride = getVariationComponent(originalFeeOverride, experiments);

  const isFeeOverrideEmpty = isEmpty(omit(['original'], feeOverride));
  if (isEmpty(fees) && isEmpty(partnerFees) && isFeeOverrideEmpty) {
    return null;
  }

  let finalFees = {};
  if (!isEmpty(fees)) {
    finalFees = fees;
  }

  if (!isEmpty(partnerFees)) {
    finalFees = partnerFees;
  }

  if (!isFeeOverrideEmpty) {
    finalFees = feeOverride;
  }

  const {
    universalFee,
    creditCardFee,
    debitCardFee,
    monthlyCost,
    acceleratedFee,
  } = finalFees;

  let feeCards = [];

  // Brazil
  if (debitCardFee && acceleratedFee) {
    feeCards = [
      { amount: debitCardFee, label: debitCardFeesLabel },
      { amount: acceleratedFee, label: creditCardFeesLabel },
    ];
    // other countries
  } else if (creditCardFee && debitCardFee) {
    feeCards = [
      { amount: debitCardFee, label: debitCardFeesLabel },
      { amount: creditCardFee, label: creditCardFeesLabel },
    ];
    // partners
  } else {
    feeCards = [{ amount: universalFee, label: transactionFeesLabel }];
  }

  const isTwoCards = feeCards.length === 2;

  const twoCardsGrid = {
    default: 12,
    kilo: 7,
    mega: 6,
    giga: 6,
  };

  const oneCardGrid = {
    default: 12,
    kilo: isTwoCards ? 5 : 6,
    mega: 4,
    giga: 3,
  };

  if (monthlyCost) {
    const monthlyCostItem = {
      amount: monthlyCost,
      label: monthlyFeesLabel,
    };

    feeCards = [monthlyCostItem, ...feeCards];
  }

  return (
    <Grid
      data-selector={formatSelector('section', DATA_SELECTOR_ID)}
      className={className}
      data-elbcontext="component:pricing_cards"
    >
      <Row>
        <StyledCol span={{ default: 12 }}>
          {headline && (
            <StyledHeadline as="h3" size="one">
              {headline}
            </StyledHeadline>
          )}
          {description && (
            <Description>
              <RichText
                richText={description}
                renderNode={inline.createRenderNode()}
                renderMark={inline.createRenderMark()}
              />
            </Description>
          )}
          <Wrapper>
            {monthlyCost && (
              <ExtraCol
                span={oneCardGrid}
                data-selector={formatSelector('col_left', DATA_SELECTOR_ID)}
              >
                <PricingCard
                  feeCards={[
                    {
                      amount: monthlyCost,
                      label: monthlyFeesLabel,
                    },
                  ]}
                />
              </ExtraCol>
            )}
            <Col
              span={isTwoCards ? twoCardsGrid : oneCardGrid}
              data-selector={formatSelector('col_right', DATA_SELECTOR_ID)}
            >
              <PricingCard feeCards={feeCards} hasExtraCard={!!monthlyCost} />
            </Col>
          </Wrapper>
          {finePrint && (
            <Col span={{ default: 12 }}>
              <FinePrint>{finePrint}</FinePrint>
            </Col>
          )}
        </StyledCol>
      </Row>
    </Grid>
  );
};

PricingCards.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.object,
  className: PropTypes.string,
  monthlyFeesLabel: PropTypes.string,
  transactionFeesLabel: PropTypes.string,
  creditCardFeesLabel: PropTypes.string,
  debitCardFeesLabel: PropTypes.string,
  feeOverride: PropTypes.object,
  finePrint: PropTypes.string,
  partnerFees: feesPropType,
};

/**
 * @component
 */
export default PricingCards;
